import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-study-toolbar',
  templateUrl: './study-toolbar.component.html',
  styleUrls: ['./study-toolbar.component.css']
})
export class StudyToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
