import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cell-highlight',
  templateUrl: './cell-highlight.component.html',
  styleUrls: ['./cell-highlight.component.css']
})
export class CellHighlightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
